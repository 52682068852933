/**
 * Upselling Flow Store
 * ----------------------------
 *
 * The flow stores holds data of the current "upselling flow", the user is engaged in.
 * The flow controls a sequence of pages and available products/options to buy in this upselling sub-flow.
 */
import type { RouteNamedMapI18n } from 'vue-router/auto-routes';

export const useUpsellingFlowStore = defineStore('upsellingFlow', () => {
    const localeRoute = useLocaleRoute();
    const { t: $t } = useNuxtApp().$i18n;
    const router = useRouter();

    const mainFlowRoute = ref<keyof RouteNamedMapI18n>();

    // The current upselling flow product config (determines the upselling flow steps)
    const productConfig = ref<ProductConfig>();

    const currentStep = computed(() => getCurrentStep(productConfig.value?.steps ?? [], router.currentRoute.value));
    const currentStepIndex = computed(() => getCurrentStepIndex(productConfig.value?.steps ?? [], router.currentRoute.value));
    const previousStep = computed(() => getPreviousFlowStep(productConfig.value?.steps ?? [], router.currentRoute.value));
    const nextStep = computed(() => getNextFlowStep(productConfig.value?.steps ?? [], router.currentRoute.value));
    const previousRoute = computed(() => previousStep.value ? localeRoute(previousStep.value?.routeName) : undefined);
    const nextRoute = computed(() => nextStep.value ? localeRoute(nextStep.value?.routeName) : undefined);
    const nextLabel = computed(() => nextStep.value ? (nextStep.value.nextLabel ?? $t('core.buttons.next')) : undefined);

    function reset() {
        productConfig.value = undefined;
    }

    return {
        mainFlowRoute,

        productConfig,

        currentStep,
        currentStepIndex,
        previousStep,
        nextStep,

        previousRoute,
        nextRoute,
        nextLabel,

        reset,
    };
}, {
    persist: storePersist.storePersistOptions,
});
