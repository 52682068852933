import { startOfDay } from 'date-fns';

/**
 * Extracts a P2P product from a given booking offer
 *
 * @param bookedOffer The offer containing the P2P data
 * @param booking - The booking data from the API schema.
 * @returns The P2P product
 */
export function transformBookingProductP2P(
    bookedOffer: ApiSchema['BookedOffer'],
    booking: ApiSchema['Booking'],
): ProductP2P | undefined {
    const passengers = booking.passengers;
    const trips = booking.trips;
    const offer = transformBookingOfferP2P(bookedOffer, passengers);
    const activeTrips = trips?.filter(trip => booking.activeTripIds?.length ? booking.activeTripIds?.includes(trip.id) : true) ?? [];
    const tripUp = activeTrips[0] ? transformTrip(activeTrips[0]) : undefined;
    const tripDown = activeTrips[1] ? transformTrip(activeTrips[1]) : undefined;
    const productConfig = getP2PProductConfig(tripUp?.destination, offer.hasEPR);

    if (!tripUp || !productConfig) {
        return undefined;
    }

    const offerPassengers = passengers.filter(p => bookedOffer.admissions?.some(a => a.passengerIds.includes(p.id))) ?? [];
    const passengerRefs = offerPassengers.map(p => p.externalRef);
    const dateFrom = startOfDay(tripUp.departureTime);
    const isRoundtrip = !!tripDown;

    return {
        config: productConfig,
        dateFrom,
        passengerRefs,
        origin: tripUp.origin,
        destination: tripUp.destination,
        isRoundtrip,
        tripUp,
        tripDown,
        offer,
    } satisfies ProductP2P;
}
