export default {
    key: 'joch-epr',
    type: 'p2p',
    name: 'core.productNames.joch-epr',
    destination: {
        ref: 'urn:uic:stn:8507364',
        name: 'Jungfrau Joch - Top of Europe',
    },
    isRoundtrip: true,
    canSwitchDirection: false,
    isUsingReductionCards: false,
    isUsingKeyCards: false,
    isUpsell: false,
    hasEPR: 'only',
    upsells: [],

    steps: [
        {
            routeName: 'offerFlow-joch-epr-steps-step-1-date',
            name: 'offerFlow.pages.joch.steps.step1',
        },
        {
            routeName: 'offerFlow-joch-epr-steps-step-2-number-of-people',
            name: 'offerFlow.pages.joch.steps.step2',
        },
        {
            routeName: 'offerFlow-joch-epr-steps-step-3-trip-selection',
            name: 'offerFlow.pages.joch.steps.step3',
        },
        {
            routeName: 'offerFlow-joch-epr-steps-step-4-trip-overview',
            name: 'offerFlow.pages.joch.steps.step4',
            hideInStepper: true,
        },
        {
            routeName: 'offerFlow-joch-epr-steps-step-5-passengers',
            name: 'offerFlow.pages.joch.steps.step5',
        },
        {
            routeName: 'offerFlow-joch-epr-steps-step-6-summary',
            name: 'offerFlow.pages.joch.steps.step6',
        },
    ],

} satisfies ProductConfigP2P;
