import { type Duration, format, isAfter, isBefore, isEqual, type Locale } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { de, enGB, fr } from 'date-fns/locale';

// TODO:
// - Try to use the new date-fns first-class TZ package, but its fuckin stupid
// - Try to maybe have a better TZ handling than zurichDate()

const locales: Record<string, Locale> = {
    de,
    fr,
    en: enGB,
};

/**
 * Creates a date object in the Zurich timezone.
 * @param args The arguments to pass to the Date constructor.
 * @returns The date in the Zurich timezone.
 */
export function zurichDate(...args: (string | number | Date | undefined | null)[]): Date {
    // @ts-expect-error Date constructor is stupid
    return toZonedTime(new Date(...args), 'Europe/Zurich');
}

/**
 * Checks if date1 is before or equal to date2.
 * @param date1 The first date.
 * @param date2 The second date.
 * @returns True if date1 is before or equal to date2.
 */
export function isBeforeOrEqual(date1: Date, date2: Date): boolean {
    return isBefore(date1, date2) || isEqual(date1, date2);
}

/**
 * Checks if date1 is after or equal to date2.
 * @param date1 The first date.
 * @param date2 The second date.
 * @returns True if date1 is after or equal to date2.
 */
export function isAfterOrEqual(date1: Date, date2: Date): boolean {
    return isAfter(date1, date2) || isEqual(date1, date2);
}

/**
 * Formats the date as 'yyyy-MM-dd\'T\'HH:mm'.
 * @param date The date to format.
 * @returns The formatted date.
 */
export function formatDateTimeISO(date?: Date): string {
    return formatDate(date, 'yyyy-MM-dd\'T\'HH:mm');
}

/**
 * Formats a given date localized to "dd. MMM" (in DE) (e.g. 01. Jan).
 *
 * @param date - The date to format.
 * @returns The formatted date.
 */
export function formatMonthMedium(date?: Date): string {
    const format = useNuxtApp().$i18n.t('core.date.formatMonthMedium');
    return formatDate(date, format);
}

/**
 * Formats a given date localized to "dd. MMM yyyy" (in DE) (e.g. 01. Jan 2022).
 *
 * @param date - The date to format.
 * @returns The formatted date.
 */
export function formatMonthMediumYear(date?: Date): string {
    const format = useNuxtApp().$i18n.t('core.date.formatMonthMediumYear');
    return formatDate(date, format);
}

/**
 * Formats a given date localized to "dd. MMM yyyy HH:mm" (in DE) (e.g. 01. Jan 2022 20:00).
 *
 * @param date - The date to format.
 * @returns The formatted date.
 */
export function formatMonthMediumYearTime(date?: Date): string {
    const format = useNuxtApp().$i18n.t('core.date.formatMonthMediumYearTime');
    return formatDate(date, format);
}

/**
 * Formats a given date localized to "E dd. MMM yyyy" (in DE) (e.g. Fr. 28. Feb. 2025).
 *
 * @param date - The date to format.
 * @returns The formatted date.
 */
export function formatWeekdayMediumYear(date?: Date): string {
    const format = useNuxtApp().$i18n.t('core.date.formatWeekdayMediumYear');
    return formatDate(date, format);
}

/**
 * Formats the date as 'HH:mm'.
 * @param date The date to format.
 * @returns The formatted date.
 */
export function formatTime(date?: Date): string {
    return formatDate(date, 'HH:mm');
}

/**
 * Formats the date using the specified format pattern.
 * @param date The date to format.
 * @param f The format pattern.
 * @returns The formatted date.
 */
export function formatDate(date: Date | undefined, f: string): string {
    if (!date) {
        return '';
    }
    try {
        return format(date, f, { locale: locales[useNuxtApp().$i18n.locale.value] });
    }
    catch (e) {
        return '';
    }
}

/**
 * Parses ISO duration
 * @param iso8601Duration
 * @returns The parsed duration in years, months, weeks, days, hours, minutes and seconds.
 */
export function parseISODuration(iso8601Duration: string): Duration {
    const iso8601DurationRegex = /(-)?P(?:([.,\d]+)Y)?(?:([.,\d]+)M)?(?:([.,\d]+)W)?(?:([.,\d]+)D)?T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?/;

    const matches = iso8601Duration.match(iso8601DurationRegex);

    return {
        years: matches?.[2] ? Number.parseInt(matches[2]) : 0,
        months: matches?.[3] ? Number.parseInt(matches[3]) : 0,
        weeks: matches?.[4] ? Number.parseInt(matches[4]) : 0,
        days: matches?.[5] ? Number.parseInt(matches[5]) : 0,
        hours: matches?.[6] ? Number.parseInt(matches[6]) : 0,
        minutes: matches?.[7] ? Number.parseInt(matches[7]) : 0,
        seconds: matches?.[8] ? Number.parseInt(matches[8]) : 0,
    };
}

/**
 * Converts date-fns duration to days
 * Dumb version of `duration.total`: https://tc39.es/proposal-temporal/docs/duration.html#total
 * @param duration
 * @returns Approximate number of days in the duration
 */
export function durationInDays(duration: Duration): number {
    let days = 0;

    days += Math.round((duration.years ?? 0) * 365.25);
    days += Math.round((duration.months ?? 0) * 30.436875);
    days += (duration.weeks ?? 0) * 7;
    days += (duration.days ?? 0);
    days += Math.round((duration.hours ?? 0) / 24);
    days += Math.round((duration.minutes ?? 0) / (24 * 60));
    days += Math.round((duration.seconds ?? 0) / (24 * 60 * 60));

    return days;
}
