export default {
    key: 'ski',
    type: 'activity',
    name: 'core.productNames.ski',
    activityId: '19fa6d77-9437-44a5-8c19-4184a16912b6',
    isUpsell: false,
    isUsingReductionCards: false,
    isUsingKeyCards: true,
    upsells: ['hotel'],

    steps: [
        {
            routeName: 'offerFlow-ski-steps-step-1-date',
            name: 'offerFlow.pages.ski.steps.step1',
        },
        {
            routeName: 'offerFlow-ski-steps-step-2-passengers',
            name: 'offerFlow.pages.ski.steps.step2',
        },
        {
            routeName: 'offerFlow-ski-steps-step-3-summary',
            name: 'offerFlow.pages.ski.steps.step3',
        },
    ],
} satisfies ProductConfigActivity;
