<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router';
import { NuxtLink } from '#components';

const {
    type = 'button',
    colorScheme = 'primary',
    layout = 'default',
    size = 'default',
    animatedHover = false,
    disabled = false,
    disabledVisually = false,
    to,
} = defineProps<{
    type?: 'button' | 'link';
    colorScheme?: 'primary' | 'secondary' | 'primary-on-dark' | 'secondary-on-dark' | 'dark' | 'transparent' | 'accent';
    size?: 'default' | 'small';
    layout?: 'default' | 'pill';
    onDark?: boolean;
    disabled?: boolean;
    disabledVisually?: boolean;
    to?: RouteLocationRaw;
    animatedHover?: boolean;
}>();

defineEmits(['click']);

const element = computed(() => {
    switch (type) {
        case 'link':
            return NuxtLink;
        case 'button':
        default:
            return 'button';
    }
});

/**
 * Composes the classes to visually style the button.
 *
 * Important: Use data-[disabled=true]:* instead of disabled:* to
 * target disabled styles, because BaseButton can be both an <a> (NuxtLink)
 * or <button> and <a> elements do not support the disabled attribute.
 */
const classes = computed(() => {
    let classes: string[] = [
        'flex',
        'items-center',
        'transition-colors',
        'data-[disabled-visually=true]:cursor-default',
        'data-[disabled=true]:pointer-events-none',
    ];

    switch (colorScheme) {
        case 'primary':
            classes.push(
                'button-primary',
                'bg-brand-a',
                'text-brand-b',
                'data-[disabled=true]:bg-brand-a-2',
                'data-[disabled=true]:text-brand-a-3',
                'data-[disabled-visually=true]:bg-brand-a-2',
                'data-[disabled-visually=true]:text-brand-a-3',
                'hover:bg-shade-2',

            );
            break;
        case 'secondary':
            classes.push(
                'button-secondary',
                'bg-white',
                'text-brand-b',
                'data-[disabled=true]:bg-shade-1',
                'data-[disabled=true]:text-shade-3',
                'data-[disabled-visually=true]:bg-shade-1',
                'data-[disabled-visually=true]:text-pale',
                'hover:bg-shade-2',
            );
            break;
        case 'primary-on-dark':
            classes.push(
                'button-primary-on-dark',
                'bg-brand-a',
                'text-brand-b',
                'data-[disabled=true]:bg-shade-6',
                'data-[disabled=true]:text-shade-5',
                'data-[disabled-visually=true]:bg-shade-6',
                'data-[disabled-visually=true]:text-shade-5',
                'hover:bg-shade-2',
            );
            break;
        case 'secondary-on-dark':
            classes.push(
                'button-secondary-on-dark',
                'bg-white',
                'text-brand-b',
                'data-[disabled=true]:bg-shade-6',
                'data-[disabled=true]:text-shade-5',
                'data-[disabled-visually=true]:bg-shade-6',
                'data-[disabled-visually=true]:text-shade-5',
                'hover:bg-shade-2',
            );
            break;
        case 'dark':
            classes.push(
                'button-dark',
                'bg-brand-b',
                'text-white',
                'data-[disabled=true]:bg-shade-1',
                'data-[disabled=true]:text-shade-3',
                'data-[disabled-visually=true]:bg-shade-1',
                'data-[disabled-visually=true]:text-pale',
                'hover:bg-shade-2',
                'hover:text-brand-b',
            );
            break;
        case 'transparent':
            classes.push(
                'button-transparent',
                'bg-transparent',
                'data-[disabled=true]:text-shade-3',
                'data-[disabled-visually=true]:text-shade-3',
                'hover:text-shade-6',
                '!px-0',

            );
            break;
        case 'accent':
            classes.push(
                'button-accent',
                'bg-accent-1',
                'text-white',
                'data-[disabled=true]:bg-accent-1/50',
                'data-[disabled=true]:text-white/50',
                'data-[disabled-visually=true]:bg-accent-1/50',
                'data-[disabled-visually=true]:text-white/50',
                'hover:bg-shade-2',
                'hover:text-brand-b',
            );
            break;
    }

    switch (layout) {
        case 'default':
            classes.push(
                'rounded-sm',
                'gap-x-3',
                'px-[18px]',
                'py-3',
            );
            break;
        case 'pill':
            classes.push(
                'rounded-full',
                'gap-x-1.5',
                'px-3',
                'py-2.5',
            );
            break;
    }

    switch (size) {
        case 'default':
            classes.push(
                'typo-button',
            );
            break;
        case 'small':
            classes.push(
                'typo-meta-s-bold',
            );
            break;
    }

    // Add animated hover effect
    if (animatedHover) {
        // if animarted, remove basic hovers
        classes = classes.filter(c => ![
            'hover:',
            'transition-colors',
        ].some(rc => c.includes(rc)));
        classes.push('slide-bg');
    }

    return classes;
});
</script>

<template>
    <component
        :is="element"
        :class="classes"
        :disabled="disabled"
        :data-disabled="disabled"
        :data-disabled-visually="disabledVisually"
        :to="to"
        @click="$emit('click', $event)"
    >
        <slot name="prependIcon" />
        <slot />
        <slot name="appendIcon" />
    </component>
</template>
