import { differenceInDays } from 'date-fns';

interface EcommerceDataItem {
    item_id: string;
    item_name?: string;
    affiliation: string;
    item_category: string;
    item_category2?: string;
    price?: number;
    quantity: number;
    keycard?: string;
    first_day_of_validity?: string;
    validity_period?: string;
    n_days_in_advance?: string;
}

export interface EcommerceData {
    currency: string | undefined;
    value: number | undefined;
    items: EcommerceDataItem[];
    transaction_id?: string;
}

export type EcommerceEventName = 'add_guest' | 'add_to_cart' | 'remove_from_cart' | 'view_cart' | 'begin_checkout' | 'add_shipping_info' | 'add_payment_info' | 'purchase';

/**
 * Track Page View for Analytics
 */
export function analyticsTrackPageView(title: string | undefined, path: string, isPageInitialLoad: boolean = true) {
    try {
        if (!window.gtmReady) {
            return;
        }

        const router = useRouter();
        const runtimeConfig = useRuntimeConfig();
        const { dataLayer } = useScriptGoogleTagManager({ id: runtimeConfig.public.gtm.id });

        const referrer = router?.options?.history?.state?.back
            ? window.location.origin + router?.options?.history?.state?.back
            : document.referrer ?? undefined;

        const event = {
            event: 'page_view',
            platform_environment: runtimeConfig.public.gtm.env,
            platform_name: 'top-of-travel',
            platform_interface_version: 'europe',
            page_url: window.location.href,
            page_url_path: window.location.pathname,
            page_url_hostname: window.location.origin,
            page_url_query_string: window.location.search,
            page_id: window.location.pathname.replace(/^\/(de|fr|en)/, ''),
            page_title: title,
            page_language: useNuxtApp().$i18n.locale.value,
            page_referrer: referrer,
            page_initial_load: isPageInitialLoad,
        };
        dataLayer.push(event);
        if (runtimeConfig.public.enableDevHelpers) {
            // eslint-disable-next-line no-console
            console.debug('analyticsTrackPageView', event);
        }
    }
    catch (error) {
        handleError(error, {}, false);
    }
}

/**
 * Track UX Interaction for Analytics
 */
export function analyticsTrackUXInteraction(elementType: string = '', elementText: string = '', elementContext: string = '') {
    try {
        if (!window.gtmReady) {
            return;
        }
        const runtimeConfig = useRuntimeConfig();
        const { dataLayer } = useScriptGoogleTagManager({ id: runtimeConfig.public.gtm.id });
        const event = {
            event: 'ux_interaction',
            element_type: elementType,
            element_text: elementText,
            element_context: elementContext,

        };
        dataLayer.push(event);
        if (runtimeConfig.public.enableDevHelpers) {
            // eslint-disable-next-line no-console
            console.debug('analyticsTrackUXInteraction', event);
        }
    }
    catch (error) {
        handleError(error, {}, false);
    }
}

/**
 * Track Errors for Analytics
 */
export function analyticsTrackError(errorCode: string = '', errorElementName: string = '', errorMessage: string = '') {
    try {
        const runtimeConfig = useRuntimeConfig();
        const { dataLayer } = useScriptGoogleTagManager({ id: runtimeConfig.public.gtm.id });
        const event = {
            event: 'error_custom',
            error_code: errorCode,
            error_element_name: errorElementName,
            error_message: errorMessage,

        };
        dataLayer.push(event);
        if (runtimeConfig.public.enableDevHelpers) {
            // eslint-disable-next-line no-console
            console.debug('analyticsTrackError', event);
        }
    }
    catch (error) {
        handleError(error, {}, false);
    }
}

/**
 * Track eCommerce Event for Analytics
 */
export function analyticsTrackEcommerce(eventName: EcommerceEventName, ecommerceData?: EcommerceData) {
    try {
        if (!window.gtmReady) {
            return;
        }
        const runtimeConfig = useRuntimeConfig();
        const { dataLayer } = useScriptGoogleTagManager({ id: runtimeConfig.public.gtm.id });
        const event = {
            event: eventName,
            ...(ecommerceData ? { ecommerce: ecommerceData } : null),
        };
        dataLayer.push({ ecommerce: null });
        dataLayer.push(event);
        if (runtimeConfig.public.enableDevHelpers) {
            // eslint-disable-next-line no-console
            console.debug('analyticsTrackEcommerce', event);
        }
    }
    catch (error) {
        handleError(error, {}, false);
    }
}

/**
 * Extract eCommerce tracking data from product or offer part
 */
export function analyticsGetEcommerceItem({ product, offerPart, passenger }: { product?: Product; offerPart: OfferPart | OfferAncillary; passenger?: Passenger }): EcommerceDataItem {
    const item: EcommerceDataItem = {
        item_id: '',
        affiliation: 'Top of Travel Online Shop',
        item_category: '',
        price: offerPart.price ? offerPart.price.amount / 100 : undefined,
        quantity: 1,
    };

    const isOfferAncillary = offerPart.type === 'insurance'; // TODO  tbd
    const isActivity = product?.config.type === 'activity';
    const isHotel = product?.config.type === 'hotel';

    if (isOfferAncillary) {
        item.item_id = offerPart.offerRef;
        item.item_name = offerPart.name;
        item.item_category = offerPart.type;
    }
    else if (isActivity) {
        const productActiity = product as ProductActivity;
        item.item_id = productActiity.config.activityId;
        item.item_name = offerPart.name;
        item.item_category = product.config.key;
        item.first_day_of_validity = formatDate(product.dateFrom, 'yyyy-MM-dd');
        item.validity_period = String(durationInDays(parseISODuration(productActiity.duration)));
        item.n_days_in_advance = String(differenceInDays(product.dateFrom, new Date()));
    }
    else if (isHotel) {
        item.item_id = product.config.name;
        item.item_name = offerPart.name;
        item.item_category = product.config.key;
        item.first_day_of_validity = formatDate(product.dateFrom, 'yyyy-MM-dd');
        item.n_days_in_advance = String(differenceInDays(product.dateFrom, new Date()));
    }

    return item;
}
