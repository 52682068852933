import { isAfter } from 'date-fns';

/**
 * Transforms the refundable p2p fulfillments from the booking and offer data.
 * A P2P product consists of both admissions and reservations.
 *
 * @param booking - The booking data from the API schema.
 * @param product - The previously transformed p2p product
 * @returns An array of transformed activity refundables.
 */
export function transformRefundablesP2P(
    booking: ApiSchema['Booking'],
    product: ProductP2P,
): (RefundableAdmission | RefundableReservation)[] {
    const refundablesAdmission = transformRefundablesAdmission(booking, product);
    const refundableReservation = transformRefundablesReservation(booking, product);

    return [...refundablesAdmission, ...refundableReservation];
}

/**
 * Transforms the refundable admission fulfillments from the booking and offer data.
 *
 * @param booking - The booking data from the API schema.
 * @param product - The previously transformed p2p product
 * @returns An array of transformed admission refundables.
 */
function transformRefundablesAdmission(booking: ApiSchema['Booking'], product: ProductP2P) {
    const refundables: RefundableAdmission[] = [];
    const offer = booking.bookedOffers?.find(o => o.offerId === product.offer?.offerId);

    // Next, get all fulfillments of the booking that correspond to admissions
    const fulfilledAdmissions = booking?.fulfillments
        // Only look at the entries in fulfillments which correspond to an admission offer
        // By looking at the ids within the booking parts of the fulfillment
        // and then checking if those ids correspond to an admission — an id within the admissions of an offer
        ?.filter(fulfillment => fulfillment.bookingParts?.some(
            bookingPart => offer?.admissions?.some(admission => admission.id === bookingPart.id),
        ));

    // Loop through the filtered admission fulfillments
    fulfilledAdmissions?.forEach((fulfillment) => {
        // Get the admission based on the id of the fulfillments' booking parts
        const admission = offer?.admissions?.find(a => fulfillment.bookingParts?.some(bp => bp.id === a?.id));
        const passenger = booking?.passengers.find(p => admission?.passengerIds?.includes(p.id));

        let trip;
        if (product.tripUp?.id && admission?.tripCoverage?.coveredTripIds?.includes(product.tripUp.id)) {
            trip = product.tripUp;
        }
        else if (product.tripDown?.id && admission?.tripCoverage?.coveredTripIds?.includes(product.tripDown.id)) {
            trip = product.tripDown;
        }
        if (!trip) {
            return;
        }

        // Determine if the product is still eligible for refund
        const isOfferExpired = isAfter(zurichDate(), zurichDate(admission?.validUntil));
        const isRefundDisabled = false;
        let isRefundExpired = false;
        let refundDeadline;

        if (admission?.afterSaleConditions && admission.afterSaleConditions.length > 0) {
            // Find the first condition where the 'condition' is 'REFUND'
            const refundCondition = admission.afterSaleConditions.find(
                condition => condition.condition === 'REFUND',
            );

            // Check if the current date is before the 'validUntil' date and after the 'validFrom' date
            isRefundExpired = !refundCondition
            || (refundCondition?.validUntil !== null && isAfter(zurichDate(), zurichDate(refundCondition?.validUntil)))
            || (refundCondition?.validFrom !== null && isAfter(zurichDate(refundCondition?.validFrom), zurichDate()));

            refundDeadline = zurichDate(refundCondition?.validUntil);
        }

        // Finally, transform and add the object to the refunds
        refundables.push({
            id: fulfillment.id,
            type: 'admission',
            name: admission?.summary ?? '',
            status: admission?.status ?? 'FULFILLED',
            product,
            trip,
            isOfferExpired,
            passenger: {
                ref: passenger?.externalRef ?? '',
                firstName: passenger?.detail?.firstName ?? '',
                lastName: passenger?.detail?.lastName ?? '',
                dateOfBirth: zurichDate(passenger?.dateOfBirth),
            },
            price: admission?.price ?? { amount: 0, currency: 'CHF' },
            refundDeadline,
            isRefundExpired,
            isRefundDisabled,
        } satisfies RefundableAdmission);
    });

    return refundables;
}

/**
 * Transforms the refundable reservation fulfillments from the booking and offer data.
 *
 * @param booking - The booking data from the API schema.
 * @param product - The previously transformed p2p product
 * @returns An array of transformed reservation refundables.
 */
function transformRefundablesReservation(booking: ApiSchema['Booking'], product: ProductP2P) {
    const refundables: RefundableReservation[] = [];
    const offer = booking.bookedOffers?.find(o => o.offerId === product.offer?.offerId);

    // Next, get all fulfillments of the booking that correspond to reservations
    const fulfilledReservations = booking?.fulfillments
        // Only look at the entries in fulfillments which correspond to an reservation offer
        // By looking at the ids within the booking parts of the fulfillment
        // and then checking if those ids correspond to an reservation — an id within the reservations of an offer
        ?.filter(fulfillment => fulfillment.bookingParts?.some(
            bookingPart => offer?.reservations?.some(reservation => reservation.id === bookingPart.id),
        ));

    // Loop through the filtered reservation fulfillments
    fulfilledReservations?.forEach((fulfillment) => {
        // Get the reservation based on the id of the fulfillments' booking parts
        const reservation = offer?.reservations?.find(a => fulfillment.bookingParts?.some(bp => bp.id === a?.id));
        const passenger = booking?.passengers.find(p => reservation?.passengerIds?.includes(p.id));

        let trip;
        if (product.tripUp?.id === reservation?.tripCoverage?.coveredTripId) {
            trip = product.tripUp;
        }
        else if (product.tripDown?.id === reservation?.tripCoverage?.coveredTripId) {
            trip = product.tripDown;
        }
        if (!trip) {
            return;
        }

        // Determine if the product is still eligible for refund
        const isOfferExpired = isAfter(zurichDate(), zurichDate(reservation?.validUntil));
        const isRefundDisabled = false;
        let isRefundExpired = false;
        let refundDeadline;

        if (reservation?.afterSaleConditions && reservation.afterSaleConditions.length > 0) {
            // Find the first condition where the 'condition' is 'REFUND'
            const refundCondition = reservation.afterSaleConditions.find(
                condition => condition.condition === 'REFUND',
            );

            // Check if the current date is before the 'validUntil' date and after the 'validFrom' date
            isRefundExpired = !refundCondition
            || (refundCondition?.validUntil !== null && isAfter(zurichDate(), zurichDate(refundCondition?.validUntil)))
            || (refundCondition?.validFrom !== null && isAfter(zurichDate(refundCondition?.validFrom), zurichDate()));

            refundDeadline = zurichDate(refundCondition?.validUntil);
        }

        // Finally, transform and add the object to the refunds
        refundables.push({
            id: fulfillment.id,
            type: 'reservation',
            // name: reservation?.summary ?? '',
            // TODO: check if backend maybe can also set name on reservation (like admission) instead of us setting a static label
            name: 'Sitzplatzreservation (todo)',

            status: reservation?.status ?? 'FULFILLED',
            product,
            trip,
            isOfferExpired,
            passenger: {
                ref: passenger?.externalRef ?? '',
                firstName: passenger?.detail?.firstName ?? '',
                lastName: passenger?.detail?.lastName ?? '',
                dateOfBirth: zurichDate(passenger?.dateOfBirth),
            },
            price: reservation?.price ?? { amount: 0, currency: 'CHF' },
            refundDeadline,
            isRefundExpired,
            isRefundDisabled,
        } satisfies RefundableReservation);
    });

    return refundables;
}
