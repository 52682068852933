import { differenceInHours, formatISODuration } from 'date-fns';

/**
 * Extracts an activity product from a given booking offer
 *
 * @param bookedOffer The offer containing the P2P data
 * @param booking - The booking data from the API schema.
 * @returns The activity product
 */
export function transformBookingProductActivity(
    bookedOffer: ApiSchema['BookedOffer'],
    booking: ApiSchema['Booking'],
): ProductActivity | undefined {
    const passengers = booking.passengers;
    const activity = bookedOffer.activities?.[0];
    const productConfig = getActivityProductConfigById(activity?.activityId);

    if (!productConfig) {
        return undefined;
    }

    const offerPassengers = passengers.filter(p => bookedOffer.activities?.some(a => a.passengerIds.includes(p.id))) ?? [];
    const passengerRefs = offerPassengers.map(p => p.externalRef);
    const dateFrom = activity?.validFrom;
    const dateUntil = activity?.validUntil;
    const durationInHours = (dateFrom && dateUntil) ? Math.abs(differenceInHours(dateFrom, dateUntil)) : 0;
    const duration = formatISODuration({ hours: durationInHours });

    // Create an offer with the corresponding offer parts for each passenger
    const offer = transformBookingOfferActivity(bookedOffer, passengers);

    return {
        config: productConfig,
        dateFrom: zurichDate(dateFrom),
        dateUntil: zurichDate(dateUntil),
        duration,
        passengerRefs,
        availableFulfillmentOptions: [],
        offer,
    } satisfies ProductActivity;
}
