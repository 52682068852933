/**
 * Maps a API Trip object to a simplified app trip object
 *
 * @param trip The API Trip object
 * @returns The simplified app trip object
 */
export default function transformTrip(trip: ApiSchema['Trip'], allOffers: OfferP2P[] = []): Trip | undefined {
    if (!trip) {
        return undefined;
    }

    const offers = allOffers?.filter(o => o.tripIds?.includes(trip.id));
    const isDiscounted = offers.some(o => o.isDiscounted);
    const isDisabled = offers.length === 0 || offers.every(o => o.hasEPR && !o.isEPRAvailable);

    return {
        id: trip.id,
        origin: transformStopPlaceRef(trip.origin as ApiSchema['StopPlaceRef']),
        destination: transformStopPlaceRef(trip.destination as ApiSchema['StopPlaceRef']),
        legs: transformLegs(trip.legs),
        departureTime: zurichDate(trip.startTime),
        arrivalTime: zurichDate(trip.endTime),
        isDisabled,
        isDiscounted,
        offers,
    };
}

/**
 * Transform a StopPlaceRef object to a Place object
 *
 * @param stopPlaceRef
 * @returns The Place object
 */
function transformStopPlaceRef(stopPlaceRef?: ApiSchema['StopPlaceRef']): Place {
    return {
        ref: stopPlaceRef?.stopPlaceRef ?? '',
        name: stopPlaceRef?.stopPlaceName ?? '',
    };
}

/**
 * Transform a list of API TripLeg objects to a list of simplified app TripLeg objects
 *
 * @param legs The list of API TripLeg objects
 * @returns The list of simplified app TripLeg objects
 */
function transformLegs(legs: ApiSchema['TripLeg'][]): TripLeg[] {
    return legs
        .filter(leg => leg.timedLeg || leg.transferLeg)
        .map((leg) => {
            if (leg.timedLeg) {
                return {
                    id: leg.id,
                    type: 'timedLeg',
                    origin: {
                        ref: leg.timedLeg.start.stopPlaceRef.stopPlaceRef ?? '',
                        name: leg.timedLeg.start.stopPlaceRef.stopPlaceName ?? '',
                    },
                    destination: {
                        ref: leg.timedLeg.end.stopPlaceRef.stopPlaceRef ?? '',
                        name: leg.timedLeg.end.stopPlaceRef.stopPlaceName ?? '',
                    },
                    departureTime: zurichDate(leg.timedLeg.start.serviceDeparture.timetabledTime),
                    arrivalTime: zurichDate(leg.timedLeg.end.serviceArrival.timetabledTime),
                    originPlatform: leg.timedLeg.start.plannedStopPointName ?? undefined,
                    destinationPlatform: leg.timedLeg.end.plannedStopPointName ?? undefined,
                    direction: undefined, // TODO
                    hasEPR: false, // TODO
                    serviceName: leg.timedLeg.service.mode?.description ?? undefined,
                    serviceType: leg.timedLeg.service.mode?.ptMode,
                    attributes: leg.timedLeg.attributes?.map(a => ({
                        code: a.code ?? '',
                        text: a.text ?? '',
                    })) ?? [],
                } satisfies TripLegTimed;
            }
            else if (leg.transferLeg) {
                return {
                    id: leg.id,
                    type: 'transferLeg',
                    origin: {
                        ref: (leg.transferLeg.start as ApiSchema['StopPlaceRef']).stopPlaceRef ?? '',
                        name: (leg.transferLeg.start as ApiSchema['StopPlaceRef']).stopPlaceName ?? '',
                    },
                    destination: {
                        ref: (leg.transferLeg.end as ApiSchema['StopPlaceRef']).stopPlaceRef ?? '',
                        name: (leg.transferLeg.end as ApiSchema['StopPlaceRef']).stopPlaceName ?? '',
                    },
                    departureTime: zurichDate(leg.transferLeg.timeWindowStart),
                    arrivalTime: zurichDate(leg.transferLeg.timeWindowEnd),
                    duration: leg.transferLeg.duration ?? '',
                    mode: leg.transferLeg.transferMode ?? '',
                } satisfies TripLegTransfer;
            }
            else {
                return undefined;
            }
        })
        .filter(Boolean) as TripLeg[]; // Remove empty/undefined objects
}
