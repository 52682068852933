export function getActivityProductConfigById(activityId: string | undefined): ProductConfigActivity | undefined {
    return configProducts
        .filter((pc): pc is ProductConfigActivity => pc.type === 'activity')
        .find(pc => pc.activityId === activityId) ?? undefined;
}

export function getHotelProductConfig(): ProductConfigHotel {
    return configProductHotel;
}

export function getP2PProductConfig(destination: Place | undefined, hasEPR: boolean | 'only'): ProductConfigP2P | undefined {
    return configProducts
        .filter((pc): pc is ProductConfigP2P => pc.type === 'p2p')
        .find((pc) => {
            return pc.destination.ref === destination?.ref && pc.hasEPR === hasEPR;
        }) ?? undefined;
}
