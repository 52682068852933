export default {
    key: 'jtp',
    type: 'activity',
    name: 'core.productNames.jtp',
    activityId: '0be07f23-6ecd-4770-b829-e2c5444ec6f4',
    isUpsell: false,
    isUsingReductionCards: true,
    isUsingKeyCards: false,
    upsells: ['hotel'],

    steps: [
        {
            routeName: 'offerFlow-jtp-steps-step-1-date',
            name: 'offerFlow.pages.jtp.steps.step1',
        },
        {
            routeName: 'offerFlow-jtp-steps-step-2-passengers',
            name: 'offerFlow.pages.jtp.steps.step2',
        },
        {
            routeName: 'offerFlow-jtp-steps-step-3-summary',
            name: 'offerFlow.pages.jtp.steps.step3',
        },
    ],
} satisfies ProductConfigActivity;
