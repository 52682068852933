/**
 * Flow Store
 * ----------------------------
 *
 * The flow stores holds data of the current "booking flow", the user is engaged in.
 * The flow controls a sequence of pages and available products/options to buy.
 */
export const useFlowStore = defineStore('flow', () => {
    const localeRoute = useLocaleRoute();
    const { t: $t } = useNuxtApp().$i18n;
    const router = useRouter();

    // The current flow product config (determines the flow steps)
    const productConfig = ref<ProductConfig>();

    const currentStep = computed(() => getCurrentStep(productConfig.value?.steps ?? [], router.currentRoute.value));
    const previousStep = computed(() => getPreviousFlowStep(productConfig.value?.steps ?? [], router.currentRoute.value));
    const nextStep = computed(() => getNextFlowStep(productConfig.value?.steps ?? [], router.currentRoute.value));
    const previousRoute = computed(() => previousStep.value ? localeRoute(previousStep.value?.routeName) : undefined);

    // Returns the goto route for the footer next button
    const nextRoute = computed(() => {
        if (nextStep.value) {
            return localeRoute(nextStep.value?.routeName);
        }
        else {
            return localeRoute({ name: 'checkout-step-1-cart' });
        }
    });

    // Returns the label for the footer next button
    const nextLabel = computed(() => {
        if (nextStep.value) {
            return nextStep.value.nextLabel ?? $t('core.buttons.next');
        }
        else {
            return $t('core.buttons.toCart');
        }
    });

    function reset() {
        productConfig.value = undefined;
    }

    return {
        productConfig,

        currentStep,
        previousStep,
        nextStep,

        previousRoute,
        nextRoute,
        nextLabel,

        reset,
    };
}, {
    persist: storePersist.storePersistOptions,
});
