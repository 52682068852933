import useConfirmDialogState from '~core/components/Layout/TheConfirmDialog/useConfirmDialogState';

export default function useConfirmActionDialog() {
    const {
        isVisible,
        heading,
        body,
        confirmCallback,
        confirmLabel,
        cancelCallback,
        cancelLabel,
    } = useConfirmDialogState();

    function openConfirmDialog(
        headingParam: string,
        bodyParam: string,
        confirmCallbackParam: () => void,
        confirmLabelParam: string,
        cancelCallbackParam: () => void,
        cancelLabelParam: string,
    ) {
        isVisible.value = true;
        heading.value = headingParam;
        body.value = bodyParam;
        confirmCallback.value = confirmCallbackParam;
        confirmLabel.value = confirmLabelParam;
        cancelCallback.value = cancelCallbackParam;
        cancelLabel.value = cancelLabelParam;
    }

    return {
        openConfirmDialog,
    };
}
